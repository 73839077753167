import React from 'react';
import RTCStream from './RTCStream';
import './HomeContent.css';

function HomeContent() {
  return (
    <div className="home-content">
      <div className="top-section">
        <div className='title-section'>
        <h2>New Case Management</h2>
        </div>
        <div className='speaker-section'></div>
        <div className='upload-section'></div>
      </div>
      


      <div className="case-cards">
        <div className='stream-block'>
        <div className="card main-speaker ">
          <div className='main-stream'><RTCStream /></div>
          <h3>Casw Allocation</h3>
          
          
        </div>
        <div className="card add-speaker">

          <div className="card speaker">
            <div className='stream-window'></div>
            <div className='speaker-title'><h3>Issue Identification</h3></div>
          </div>

          <div className="card speaker">
            <div className='stream-window'></div>
            <div className='speaker-title'><h3>Issue Identification</h3></div>
          </div>


        </div>
        </div>
        <div className="task-cards">
          <div className="task-card active">
            <h3>Request Processing</h3>
          </div>
          <div className="task-card">
            <h3>Problem Resolution</h3>
          </div>
          <div className="task-card">
            <h3>Customer Communication</h3>
          </div>
          <div className="task-card">
            <h3>Testing and Verification</h3>
          </div>
          <div className="task-card">
            <h3>Customer Notification</h3>
          </div>
          <div className="task-card">
            <h3>Customer Satisfaction</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeContent;
