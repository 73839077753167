import React from 'react';
import HomeContent from './HomeContent';
import AboutContent from './AboutContent';
import ServicesContent from './ServicesContent';
import ContactContent from './ContactContent';
import './Content.css';

function Content({ activeItem }) {
  return (
    <div className="Content">
      {activeItem === 'home' && <HomeContent />}
      {activeItem === 'about' && <AboutContent />}
      {activeItem === 'services' && <ServicesContent />}
      {activeItem === 'contact' && <ContactContent />}
    </div>
  );
}

export default Content;
