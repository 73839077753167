// src/components/Header.js
import React from 'react';
import './Header.css'; 
import logo from '../assets/amocrm.png';  
import profileimage from '../assets/Piotr_Biegun.jpg'; 


function Header() {
  return (
    <header className="main-header">
    <div className="logo-container">
      <a href="/" className="logo-link" aria-label="SugarCRM">
        <img src={logo} alt="SugarCRM Logo" className="logo" />
      </a>
    </div>
      
      <nav className="nav-menu">
        <ul className="nav-list">
          <li><a href="/" className="nav-item">Relationship</a></li>
          <li><a href="/" className="nav-item">Opportunities</a></li>
          <li><a href="/" className="nav-item">Leads</a></li>
          <li><a href="/" className="nav-item">Calendar</a></li>
          <li><a href="/" className="nav-item active">Cases</a></li>
          <li><a href="/" className="nav-item">Reports</a></li>
          <li><a href="/" className="nav-item">Quotes</a></li>
        </ul>
      </nav>

      <div className="header-actions">
        <button className="icon-button search" aria-label="Search"><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.3333 9.33333H9.80667L9.62 9.15333C10.2733 8.39333 10.6667 7.40667 10.6667 6.33333C10.6667 3.94 8.72667 2 6.33333 2C3.94 2 2 3.94 2 6.33333C2 8.72667 3.94 10.6667 6.33333 10.6667C7.40667 10.6667 8.39333 10.2733 9.15333 9.62L9.33333 9.80667V10.3333L12.6667 13.66L13.66 12.6667L10.3333 9.33333ZM6.33333 9.33333C4.67333 9.33333 3.33333 7.99333 3.33333 6.33333C3.33333 4.67333 4.67333 3.33333 6.33333 3.33333C7.99333 3.33333 9.33333 4.67333 9.33333 6.33333C9.33333 7.99333 7.99333 9.33333 6.33333 9.33333Z" fill="currentColor"></path>
</svg></button>
        <button className="icon-button messages" aria-label="Messages"><svg width="24" height="24" className="field__icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.7144 6.76813L2.95238 6.19626V7.149V17.6V18.0762H3.42857H20.5714H21.0476V17.6V7.1504V6.19678L20.2853 6.76974L12.2839 12.7838H11.7302L3.7144 6.76813ZM19.4724 6.78026L20.6085 5.92381H19.1857H4.81429H3.39148L4.52765 6.78026L11.7134 12.1969L12 12.4129L12.2866 12.1969L19.4724 6.78026ZM2.47619 18.0999V5.90007L2.90872 5.47619H21.0913L21.5238 5.90007V18.0999L21.0913 18.5238H2.90872L2.47619 18.0999Z" stroke="currentColor" strokeWidth="0.952381"></path>
</svg></button>
        <button className="icon-button notifications" aria-label="Notifications"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.00065 16.083C9.77982 16.083 10.4173 15.4455 10.4173 14.6663H7.58398C7.58398 15.4455 8.22148 16.083 9.00065 16.083ZM13.2507 11.833V8.29134C13.2507 6.11676 12.0961 4.29634 10.0632 3.81467V3.33301C10.0632 2.74509 9.58857 2.27051 9.00065 2.27051C8.41273 2.27051 7.93815 2.74509 7.93815 3.33301V3.81467C5.91232 4.29634 4.75065 6.10967 4.75065 8.29134V11.833L3.33398 13.2497V13.958H14.6673V13.2497L13.2507 11.833ZM11.834 12.5413H6.16732V8.29134C6.16732 6.53467 7.2369 5.10384 9.00065 5.10384C10.7644 5.10384 11.834 6.53467 11.834 8.29134V12.5413Z" fill="currentColor"></path>
</svg></button>
        <div className="profile-container">
        <img src={profileimage} alt="SugarCRM Logo" className="logo" />
        </div>
      </div>
    </header>
  );
}

export default Header;
