import React from 'react';

function ContactContent() {
  return (
    <div className="contact-content">
      <h2>Contact Us</h2>
      <p>Feel free to reach out to us at any time.</p>
    </div>
  );
}

export default ContactContent;
