import React, { useRef, useEffect, useState } from 'react';
import './RTCStream.css'; // Подключаем стили

function RTCStream() {
  const videoRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false); // Статус потока
  const [isVideoStarted, setIsVideoStarted] = useState(false); // Статус начала видео
  const [mediaStream, setMediaStream] = useState(null); // Для хранения активного потока

  // Функция для запуска видеопотока
  const startStreaming = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setMediaStream(stream); // Сохраняем поток
        setIsVideoStarted(true); // Видео активно
        setIsStreaming(true); // Поток запущен
      }
    } catch (err) {
      console.error('Error accessing media devices.', err);
    }
  };

  // Функция для остановки видеопотока
  const stopStreaming = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach(track => track.stop()); // Останавливаем все треки
      setIsStreaming(false); // Поток остановлен
      setIsVideoStarted(false); // Видео завершено
    }
  };

  // Очищаем видеопоток при размонтировании компонента
  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop());
      }
    };
  }, [mediaStream]);

  return (
    <div className="rtc-stream-container">
      {!isStreaming && (
        <div className="placeholder">
          <p>Video is not active</p>
        </div>
      )}
      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        style={{ display: isVideoStarted ? 'block' : 'none' }} // Скрываем видео, пока не начался поток
      />
      {!isStreaming ? (
        <button className="start-button" onClick={startStreaming}>
          Start Video
        </button>
      ) : (
        <button className="stop-button" onClick={stopStreaming}>
          Stop Video
        </button>
      )}
    </div>
  );
}

export default RTCStream;
