import React from 'react';
import './Sidebar.css';

function Sidebar({ setActiveItem }) {
  return (
    <nav className="Sidebar">
      <ul>
        <li onClick={() => setActiveItem('home')}>🏠 Home</li>
        <li onClick={() => setActiveItem('about')}>📄 About</li>
        <li onClick={() => setActiveItem('services')}>🛠 Services</li>
        <li onClick={() => setActiveItem('contact')}>✉️ Contact</li>
      </ul>
    </nav>
  );
}

export default Sidebar;
