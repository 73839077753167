import React, { useState } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import './App.css';
import './components/App.css';

function App() {
  const [activeItem, setActiveItem] = useState('home');

  return (
    <div className="App">
      <Header />
      <div className="App-container">
        <Sidebar setActiveItem={setActiveItem} /> 
        <Content activeItem={activeItem} /> 
      </div>
    </div>
  );
}

export default App;
