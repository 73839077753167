import React from 'react';

function ServicesContent() {
  return (
    <div className="services-content">
      <h2>Our Services</h2>
      <p>We offer a variety of services to help you succeed.</p>
    </div>
  );
}

export default ServicesContent;
