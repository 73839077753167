import React from 'react';

function AboutContent() {
  return (
    <div className="about-content">
      <h2>About Us</h2>
      <p>We are a company that does amazing things! Here's some information about us.</p>
    </div>
  );
}

export default AboutContent;
